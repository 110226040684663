export enum UMBRELLA_LIMIT_ACTION_OLD {
  unset = 99,
  unlimited = 0,
  week = 1,
  month = 2
}

export enum UmbrellaLimitAction {
  LOCK = 1,
  MAIL = 2
}

export enum UmbrellaLimitPeriod {
  WEEK = 1,
  MONTH = 2,
  YEAR = 3
}

export enum UMBRELLA_BILLING_MODE {
  prepaid = 1,
  postpaid = 2,
  unlimited = 3
}

export interface UmbrellaAccount {
  umbrellaAccName: string;
  displayName: string | null;
  userAccounts: number[];
  firstSeenDate: string;
  timeLimitMinutes: number;
  billingMode: UMBRELLA_BILLING_MODE;
  limitAction: UmbrellaLimitAction;
  limitEnabled: true;
  limitPeriod: UmbrellaLimitPeriod;
  expirationDate: string;
  isCorporation: boolean;
  enableClever: boolean;
  enableExternalId: boolean;
  useLtiSourcedid: boolean;
}

export interface UmbrellaAccountFull {
  name: string;
  timeLimitMinutes: number;
  expirationDate: string;
  quantizeMinutes: number;
  enabled: boolean;
  contactEmail: string;
  contactName: string;
  displayName: string;
  limitEnabled: true;
  limitPeriod: number;
  limitTimeMinutes: number;
  limitAction: UMBRELLA_LIMIT_ACTION_OLD | null;
  writingLabEnabled: boolean;
  billingMode: UMBRELLA_BILLING_MODE;
}
