import MuiRating from "@material-ui/lab/Rating";
import * as React from "react";

import styled from "~/components/core/styled";
import LightTooltip from "~/components/core/Tooltip";

interface Props {
  rating: number;
  noTooltip?: boolean;
}

const Rating: React.FC<Props> = ({ rating, noTooltip }: Props): JSX.Element => {
  const content = (
    <Wrapper>
      <StyledRating value={rating} precision={0.1} readOnly />
    </Wrapper>
  );
  if (noTooltip) {
    return content;
  }
  return (
    <LightTooltip title={rating ? rating.toFixed(1) : ""} placement="top" arrow>
      {content}
    </LightTooltip>
  );
};

export default Rating;

const StyledRating = styled(MuiRating)`
  color: ${({ theme }): string => theme.palette.secondary.main};
  stroke: ${({ theme }): string => theme.palette.secondary.main};

  .MuiRating-iconEmpty {
    color: ${({ theme }): string => theme.palette.common.white};
  }
`;

const Wrapper = styled.div`
  height: 24px;
`;
